import React, { useMemo } from "react";
import { filterGroupType, filterValuesType } from "../types";


export default function FilterItem({
    title,
    filesGroup,
    filterValues,
    onChange,
    filesGroupFiltered
}: {
    title: string,
    filesGroup: filterGroupType[],
    filterValues: filterValuesType,
    onChange,
    filesGroupFiltered: filterGroupType[],
}) {

    const currentParametrFilteredBy = useMemo(() => Object.keys(filterValues).filter((key) => filterValues[key] !== undefined), [filterValues]);

    const onFilterValueChange = (event) => {
        const resultValue = event.target.value !== "all" ? event.target.value : undefined;

        onChange(event.target.name, resultValue);
    };

    function currentCountFiles(currentFile: filterGroupType, filteredFilesArray: filterGroupType[]) {
        let result: number;

        const activeInput: filterGroupType | undefined = filteredFilesArray.find(
            (item) => currentFile.title === item.title
        );

        if (currentParametrFilteredBy.length > 1 || currentFile.name !== currentParametrFilteredBy[0]) {
            result = activeInput ? activeInput.count : 0;
        } else {
            result = currentFile.count;
        }

        return result;
    }

    return (
        <>
            <h4>{title}</h4>
            <div className="filter-group-list format-list">
                {filesGroup.map((item, id) => (
                    <div className="filter-item" key={id}>
                        <label
                            className={`filter-item-label ${currentCountFiles(item, filesGroupFiltered)
                                ? "active"
                                : "disabled"
                                }`}>
                            <input
                                type="radio"
                                className="filter-item-input size-checkbox"
                                name={item.name}
                                value={item.value}
                                checked={item.isChecked}

                                onChange={(event) => onFilterValueChange(event)}
                            />
                            {item.title}
                            <div className="filter-item-amount">
                                {currentCountFiles(item, filesGroupFiltered)}
                            </div>
                        </label>
                    </div>
                ))}
            </div>

        </>
    );
}
