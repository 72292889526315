import React from "react";
import { FileType } from "../types";
import { GET_FILES_ARHIVE } from "../../graphQl/queries/files.js";
import { useLazyQuery } from "@apollo/client";

function getHashFile(files: FileType[]) {
    let hashFileArray: Array<string> = files.map((item) => item.hash);

    return hashFileArray;
}

export default function DownloadArchive({
    title,
    filteredFiles,
}: {
    title: string;
    filteredFiles: FileType[];
}) {
    const [
        getArchiveLink
    ] = useLazyQuery<{ filesArchiveByHashes }>(GET_FILES_ARHIVE);

    function downloadAllHandler() {
        const filteredFilesHashArray: Array<string> = getHashFile(filteredFiles);

        getArchiveLink(
            {
                variables:
                    { filesHashes: filteredFilesHashArray }
            }
        ).then(({ data }) => {
            if (!data || !data.filesArchiveByHashes) {
                alert('Нет файлов для скачивания. Измените значения фильтра');
                return null;
            }

            const path = data?.filesArchiveByHashes;
            const link = document.createElement('a');
            link.href = path;
            link.setAttribute(
                'download',
                'Archive.zip',
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
    }

    return (
        <>
            <h4>{title}</h4>
            <button className="btn btn-primary" onClick={downloadAllHandler}>Скачать</button>
        </>
    );
}
