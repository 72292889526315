import React, { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_CAMPAIGN } from "../graphQl/queries/campaigns";
import { GroupType } from "../types";
import Files from "./Files/index.tsx";
import filterIco from "../img/filter-ico.png";
import errorImage from "../img/cat.png";
import Filter from "./Filter/index.tsx";
import { FileType, filterValuesType } from "./types";
import { FORMAT, PLATFORM, EXTENSION } from "./constants.ts";

function filterValuesFromUrl(params) {
    if (!params) {
        return {};
    }

    let result = {};

    for (let key in params) {
        if (key === FORMAT || key === PLATFORM || key === EXTENSION) {
            result = { ...result, [key]: params[key] }
        }
    }

    return result;
}

export default function ClientPageGraphQL() {
    const params = useMemo(
        () => Object.fromEntries(new URLSearchParams(window.location.search)),
        [window.location.search]
    );

    const [filterActive, setfilterActive] = useState<boolean>(false);
    const [filterValues, setFilterValues] = useState<filterValuesType>(() => filterValuesFromUrl(params));

    const onFilterValueChange = (filterName, value) => {
        setFilterValues({ ...filterValues, [filterName]: value });
        setFilterValuesInUrl({ [filterName]: value });
    };

    const toggleFilter = () => {
        setfilterActive((current) => !current);
    };
    const resetFilter = () => {
        setFilterValues({});
        setFilterValuesInUrl({ [FORMAT]: undefined, [PLATFORM]: undefined, [EXTENSION]: undefined });
    };

    function setFilterValuesInUrl(filterValuesObject) {
        const url = new URL(window.location.href);

        for (let key in filterValuesObject) {
            filterValuesObject[key] ? url.searchParams.set(key, filterValuesObject[key]) : url.searchParams.delete(key)
        }
        window.history.pushState(null, "", url.toString());
    }

    const { loading, error, data } = useQuery<{ clientCampaign: GroupType }>(GET_CAMPAIGN, {
        variables: { clientHash: params["client"], hash: params["campaign"] }
    });

    const filteredFilesData = useMemo(() => {
        const isSizeName: boolean = Boolean(filterValues[FORMAT]);
        const isPlatformName: boolean = Boolean(filterValues[PLATFORM]);
        const isExtensionName: boolean = Boolean(filterValues[EXTENSION]);
        let filteredFiles: FileType[] = data ? data.clientCampaign.fileSet : [];

        filteredFiles = filteredFiles.filter((obj) => {
            const result = isSizeName ? obj.title === filterValues[FORMAT] : obj;

            return result;
        });

        filteredFiles = filteredFiles.filter((obj) => {
            const result = isPlatformName ? obj.platform.title === filterValues[PLATFORM] : obj;

            return result;
        });

        filteredFiles = filteredFiles.filter((obj) => {
            const result = isExtensionName ? obj.fileExtension === filterValues[EXTENSION] : obj;

            return result;
        });

        return filteredFiles;

    }, [filterValues, data]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return (
            <div className="error-block">
                <h1>Страница недоступна</h1>
                <p>Проверьте корректность ссылки</p>
                <img src={errorImage} alt="error"/>
            </div>
        )
    }

    return (
        <div className="client-page-wrap">
            <div className="client-top">
                <h1 className="client-name">{data.clientCampaign.title}</h1>

                {filterValues[FORMAT] || filterValues[PLATFORM] || filterValues[EXTENSION] ? (
                    <button className="filter-reset btn-primary" onClick={resetFilter}>
                        Сбросить фильтры
                    </button>
                ) : null}
                <div onClick={toggleFilter} className={`filter-btn ${filterActive ? "_active" : ""}`}>
                    <img src={filterIco} alt="filterIco" />
                </div>
                <Filter
                    filterActive={Boolean(filterActive)}
                    filterValues={filterValues}
                    filteredFiles={filteredFilesData}
                    files={data.clientCampaign.fileSet}
                    onChange={onFilterValueChange}
                />
            </div>
            {!params["client"] && !params["campaign"] ? (
                <h1>Страница недоступна</h1>
            ) : (
                <Files files={filteredFilesData} />
            )}
        </div>
    );
}
