import {gql} from '@apollo/client';

export const GET_FILES_BY_CLIENT_CAMPAIGN_PLATFORM = gql`
  query allFilesByClientCampaignPlatform($clientHash: String!, $campaignHash: String!, $platformHash: String!) {
    allFilesByClientCampaignPlatform(clientHash: $clientHash, campaignHash: $campaignHash, platformHash: $platformHash) {
      hash
      title
      width
      height
      file
      absoluteFilePath
      fileExtension
      fileSize
      unarchivedFiles {
        hash
        title
        path
        absoluteFilePath
      }
      client {
        hash
        title
      }
      campaign {
        hash
        title
      }
      platform {
        hash
        title
      }
    }
  }
`;

export const GET_FILES_ARHIVE = gql`
  query filesArchiveByHashes($filesHashes: [String]) {
    filesArchiveByHashes(filesHashes: $filesHashes) 
  }  
`;