import React, { useMemo } from "react";
import { FileType, filterGroupType, filterValuesType } from "../types";
import { FORMAT, PLATFORM, EXTENSION } from "../constants.ts";
import FilterItem from "../FilterItem/index.tsx";
import DownloadArchive from "../DownloadArhive/index.tsx";


function getFormatFile(files: FileType[]) {
    let fullSizeArray: Array<string> = files.map((file) => file.title);

    return fullSizeArray;
}

function getPlatformFile(files: FileType[]) {
    let platformFileArray: Array<string> = files.map((file) => file.platform.title);

    return platformFileArray;
}

function getExtensionFile(files: FileType[]) {
    let extensionFileArray: Array<string> = files.map((file) => file.fileExtension);

    return extensionFileArray;
}

export default function Filter({
    files,
    onChange,
    filteredFiles,
    filterValues,
    filterActive
}: {
    files: FileType[];
    onChange;
    filteredFiles: FileType[];
    filterValues: filterValuesType;
    filterActive: boolean;
}) {
    const allFilesCount: number = useMemo(() => files.length, [files]);

    const formatFile: Array<string> = useMemo(() => getFormatFile(files), [files]);
    const platformFile: Array<string> = useMemo(() => getPlatformFile(files), [files]);
    const extensionFile: Array<string> = useMemo(() => getExtensionFile(files), [files]);

    const formatFileFiltered: Array<string> = useMemo(() => getFormatFile(filteredFiles), [filteredFiles]);
    const platformFileFiltered: Array<string> = useMemo(() => getPlatformFile(filteredFiles), [filteredFiles]);
    const extensionFileFiltered: Array<string> = useMemo(() => getExtensionFile(filteredFiles), [filteredFiles]);

    const filesFormatGroup: filterGroupType[] = useMemo(() => getFilterGroup(formatFile, FORMAT), [filterValues]);
    const filesPlatformGroup: filterGroupType[] = useMemo(() => getFilterGroup(platformFile, PLATFORM), [filterValues]);
    const filesExtensionGroup: filterGroupType[] = useMemo(() => getFilterGroup(extensionFile, EXTENSION), [filterValues]);

    const filesFormatGroupFiltered: filterGroupType[] = useMemo(() => getFilterGroup(formatFileFiltered, FORMAT), [filteredFiles]);
    const filesPlatformGroupFiltered: filterGroupType[] = useMemo(() => getFilterGroup(platformFileFiltered, PLATFORM), [filteredFiles]);
    const filesExtensionGroupFiltered: filterGroupType[] = useMemo(() => getFilterGroup(extensionFileFiltered, EXTENSION), [filteredFiles]);

    function getFilterGroup(array, name: string) {
        const repСounting = array.reduce(function (acc, el) {
            acc[el] = (acc[el] || 0) + 1;
            return acc;
        }, {});

        const selectedItem = Object.keys(repСounting).find((item) =>
            Object.values(filterValues).some((key) => item === key)
        );

        let resultArray: filterGroupType[] = [
            {
                title: "Все",
                value: "all",
                count: allFilesCount,
                name: name,
                isChecked: !selectedItem
            }
        ];

        for (let key in repСounting) {
            resultArray.push({
                title: key,
                value: key,
                count: repСounting[key],
                name: name,
                isChecked: selectedItem === key
            });
        }

        return resultArray;
    }

    return (
        <div className={`filter-wrap ${filterActive ? "_active" : ""}`}>
            <div className="filter-content">
                <FilterItem title="Формат" onChange={onChange} filesGroup={filesFormatGroup} filterValues={filterValues} filesGroupFiltered={filesFormatGroupFiltered} />
                <FilterItem title="Платформа" onChange={onChange} filesGroup={filesPlatformGroup} filterValues={filterValues} filesGroupFiltered={filesPlatformGroupFiltered} />
                <FilterItem title="Тип" onChange={onChange} filesGroup={filesExtensionGroup} filterValues={filterValues} filesGroupFiltered={filesExtensionGroupFiltered} />
                <DownloadArchive title='Скачать проект' filteredFiles={filteredFiles} />
            </div>
        </div>
    );
}
