import React from "react";
import { FileType } from "../types";

export default function Files({ files }: { files: FileType[] }) {

	return (
		<>
			<div className="client-content">
				<div className="baners-list">
					{files.map((file) => {
						if (file.fileExtension === ".zip") {
							let pathUnarchivedFile = file.unarchivedFile.length
								? file.unarchivedFile[0].path.split("index")[0]
								: null;
							return (
								<div className="baners-list-item" key={file.hash}>
									<div className="baners-list-item--name">
										<a href={file.absoluteFilePath} target="_blank" rel="noreferrer">
											{file.platform.title}: {file.title}
										</a>
										<a href={file.absoluteFilePath} download={true}>
											({file.fileExtension.replace(".", "")})
										</a>
									</div>
									<div className="baners-list-item--frame">
										{pathUnarchivedFile ? (
											<iframe
												width={file.width}
												height={file.height}
												src={file.unarchivedFile[0].absoluteFilePath}></iframe>
										) : null}
									</div>
								</div>
							);
						} else {
							return (
								<div className="baners-list-item" key={file.hash}>
									<div className="baners-list-item--name">
										<a href={file.absoluteFilePath} target="_blank" rel="noreferrer">
											{file.platform.title}: {file.width}x{file.height}
										</a>
										<a
											href={file.absoluteFilePath}
											download={true}
											target="_blank"
											rel="noreferrer">
											({file.fileExtension.replace(".", "")})
										</a>
									</div>
									<div className="baners-list-item--img">
										<img src={file.absoluteFilePath} alt={file.title} />
									</div>
								</div>
							);
						}
					})}
				</div>
			</div>
		</>
	);
}
