import { gql } from '@apollo/client';

export const GET_CAMPAIGNS = gql`
  query clientCampaigns($clientHash: String!) {
    clientCampaigns(clientHash: $clientHash) {
      hash
      title
      client {
        hash
        title
      }
    }
  }
`;
export const GET_CAMPAIGN = gql`
  query clientCampaign($clientHash: String!, $hash: String!) {
    clientCampaign(clientHash: $clientHash, hash: $hash) {
      hash
      title
      client {
        hash
        title
      }
    fileSet {
      id
      title
      width
      height
      file
      absoluteFilePath
      fileExtension
      fileSize
      hash
      platform {
        hash
        title
      }
      unarchivedFile {
        hash
        path
        absoluteFilePath
        title
      }
    }
    }
  }
`;
