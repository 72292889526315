import React  from 'react'
import './App.css';
import {ApolloProvider} from '@apollo/client';
import apolloClient from './graphQl/getClient'
import ClientsPageGraphQl from './ClientPageGraphQL/index.tsx';

function App() {
    return (
        <ApolloProvider client={apolloClient}>
            <div className="App">
               <ClientsPageGraphQl />
            </div>
        </ApolloProvider>
    );
}

export default App;
